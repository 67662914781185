import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/oida-is-des-org-blog/oida-is-des-org-blog/src/templates/blog-post-template.tsx";
import { InlineCode } from '../../components/inline-code/inline-code';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Ein deutlicher Kontrast zwischen Text und Hintergrund hilft allen. Sowohl Menschen mit Sehschwäche als auch jenen,
die bei grellem Sonnenlicht auf ihr Display starren. Aus diesem Grund enthalten die
`}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/WCAG21/Understanding/contrast-minimum.html"
      }}>{`WCAG`}</a>{` diverse Mindest-Anforderungen für das
Kontrastverhältnis von Text bzw. UI-Komponenten zum Hintergrund.`}</p>
    <p>{`Für Menschen mit Sehbeeinträchtigung ist das aber nicht immer ausreichend. Sie brauchen spezifische Farbkombinationen
mit hohem Kontrast zur Verbesserung der Lesbarkeit. Assistive Technologien wie der
`}<a parentName="p" {...{
        "href": "https://assistivlabs.com/assistive-tech/display/high-contrast-mode"
      }}>{`High Contrast Mode`}</a>{` in Windows überschreiben
aktiv die Farben einer Website, um Nutzer:innen mit Sehschwäche zu helfen.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.66666666666666%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAQFA//EABYBAQEBAAAAAAAAAAAAAAAAAAQBA//aAAwDAQACEAMQAAABmNqV5nqLBVf/xAAbEAEAAgIDAAAAAAAAAAAAAAABAAIDESEiM//aAAgBAQABBQIlOospy4vNdP8A/8QAFxEAAwEAAAAAAAAAAAAAAAAAAAIRAf/aAAgBAwEBPwFFulP/xAAZEQACAwEAAAAAAAAAAAAAAAAAAwEEESH/2gAIAQIBAT8BsNzSF8P/xAAYEAACAwAAAAAAAAAAAAAAAAABEQACIP/aAAgBAQAGPwKGyYz/AP/EABoQAQACAwEAAAAAAAAAAAAAAAEAESExUWH/2gAIAQEAAT8h2jFKm/PYLSAlYhw9gNyxmADByf/aAAwDAQACAAMAAAAQ7M//xAAYEQADAQEAAAAAAAAAAAAAAAAAAREhMf/aAAgBAwEBPxCClnRzkP/EABgRAQADAQAAAAAAAAAAAAAAAAEAEUGx/9oACAECAQE/EAKrTsvDP//EABsQAQEAAwADAAAAAAAAAAAAAAERACFRMUHB/9oACAEBAAE/EEpObuLoAMedsNJIKvrIVS0vIL8xuqGI8y4U0BoBn//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Eine Mischung aus verschiedenen Farben auf einer Leinwand.",
          "title": "Eine Mischung aus verschiedenen Farben auf einer Leinwand.",
          "src": "/static/136267c5e3abf2262b79bb7ff5634d39/e5166/pexels-steve-johnson-color-palette.jpg",
          "srcSet": ["/static/136267c5e3abf2262b79bb7ff5634d39/f93b5/pexels-steve-johnson-color-palette.jpg 300w", "/static/136267c5e3abf2262b79bb7ff5634d39/b4294/pexels-steve-johnson-color-palette.jpg 600w", "/static/136267c5e3abf2262b79bb7ff5634d39/e5166/pexels-steve-johnson-color-palette.jpg 1200w", "/static/136267c5e3abf2262b79bb7ff5634d39/b17f8/pexels-steve-johnson-color-palette.jpg 1600w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{`
`}<em parentName="p">{`Foto: © Steve Johnson / pexels.com`}</em></p>
    <p>{`Ich zeige euch wie der Forced Colors Modus bei Websites funktioniert und wie ihr eure Inhalte mit einfachen
CSS-Tricks optimieren könnt.`}</p>
    <h2>{`Wie funktioniert der Forced Colors Modus?`}</h2>
    <p>{`Der Forced Colors Modus ist ein allgemeiner Browser-Standard, der in
`}<a parentName="p" {...{
        "href": "https://www.w3.org/TR/css-color-adjust-1/#forced"
      }}>{`CSS Color Adjustment Module Level 1`}</a>{` definiert wurde.
Betriebssysteme sollen vordefinierte Farb-Paletten anbieten und es Nutzer:innen ermöglichen, ihre eigenen
Farbkombinationen zu definieren.`}</p>
    <p>{`In der Spezifikation heißt es:`}</p>
    <blockquote lang="en">
    When <strong>forced colors mode</strong> is active (...), colors on the element are force-adjusted to the user’s preferred color palette.
    </blockquote>
    <p>{`Das bedeutet, dass Farbwerte durch `}<a parentName="p" {...{
        "href": "https://www.w3.org/TR/css-color-4/#css-system-colors"
      }}>{`System-Farben`}</a>{` ersetzt werden, welche
der definierten Farbpalette entsprechen. Zu den betroffen CSS-Eigenschaften zählen:`}</p>
    <ul>
      <li parentName="ul">
        <InlineCode mdxType="InlineCode">color</InlineCode>
      </li>
      <li parentName="ul">
        <InlineCode mdxType="InlineCode">background-color</InlineCode>
      </li>
      <li parentName="ul">
        <InlineCode mdxType="InlineCode">border-color</InlineCode>
      </li>
      <li parentName="ul">
        <InlineCode mdxType="InlineCode">outline-color</InlineCode> 
      </li>
      <li parentName="ul">
        <InlineCode mdxType="InlineCode">fill</InlineCode>
      </li>
    </ul>
    <p>{`Andere Eigenschaften wie `}<InlineCode mdxType="InlineCode">{`box-shadow`}</InlineCode>{` und `}<InlineCode mdxType="InlineCode">{`text-shadow`}</InlineCode>{` werden
auf `}<InlineCode mdxType="InlineCode">{`none`}</InlineCode>{` gesetzt. Um euch ein Beispiel zu geben, so sieht mein Blog im Forced Colors Modus
mit dunkler Farbpalette aus:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "934px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "72.33333333333334%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAABJ0AAASdAHeZh94AAACDElEQVQ4y32S227aQBCGx+tdXNnG5xqCDT5hoDgRASsoCFLU3JH3f58/2jUlaUi5+DWjsefbOVFREI5HwvMzIc8JsxlhMiHsdoS67nwZW60Itk0gIjDBoP/Qz5ZD8O6bphFIQl5eOsB6TVguCYtFF2vbD/90IhhGB9QNBmELBeWWgNEjBP4ZSCTwIQ4i/ZP4P2KMQwgOrnPoTFfi0nIBTRMQQoAklbGObtsWojBAHEeI4xC9HgfnDELoyuq6fiWNaarqc3VSFwe+78NxHBiGAdd10e/3wTlXifJ1xpiS9C9ATcNnBnX0Lvj29obfxyOetltst1u07Qa73Q6HwwGbzRr7wwFPbYtm2SjYF9A18M/rK/b7A06nEx4fV1c/f1PN98C/StIUVTVFWVbIJhmSJEFRlsiyCcbjFFmWIU3HKIsSeV4gCILbwDiOFVAmp2mqgHKOpmXBtEw4Th+WZcG2bTiOC8bEbaDnuRgMY4RhiDAI1GJkYv8McD1PPeB5ATxfXgSBaTeAReGi3QRomhr3zQPqusav5RIP9w3KokCSpEqjUYI8H6PIQzCm/R/ouh7uhiNE0U8MBgPcjUZqDFEUXs7INE3VvrRE7HbLslU59OFwgEmWqcVkWY4wHKAoSkynUxUryxJVVcH3vNvAUZJgNp+phPliriB5PsG0jhCGPjzPRxD4aruyYnnkX4HvZ71GguBQt/4AAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Eine Unterseite von \"Oida, is des org\" mit aktivem Forced Colors Modus.",
          "title": "Eine Unterseite von \"Oida, is des org\" mit aktivem Forced Colors Modus.",
          "src": "/static/7adfa1d753cf88b3c2caa77167718657/078fe/oida-is-des-org-blog-high-contrast.png",
          "srcSet": ["/static/7adfa1d753cf88b3c2caa77167718657/5a46d/oida-is-des-org-blog-high-contrast.png 300w", "/static/7adfa1d753cf88b3c2caa77167718657/0a47e/oida-is-des-org-blog-high-contrast.png 600w", "/static/7adfa1d753cf88b3c2caa77167718657/078fe/oida-is-des-org-blog-high-contrast.png 934w"],
          "sizes": "(max-width: 934px) 100vw, 934px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <h2>{`Wie ihr den Forced Colors Modus testet`}</h2>
    <p>{`Mithilfe der
`}<a parentName="p" {...{
        "href": "https://developer.chrome.com/docs/devtools/rendering/emulate-css/#emulate-css-media-feature-forced-colors"
      }}>{`Entwicklertools in Chrome und Edge`}</a>{`
könnt ihr den Forced Colors Modus emulieren und die Sichtbarkeit eurer Inhalte prüfen:`}</p>
    <ol>
      <li parentName="ol">{`Öffnet den Rendering-Tab.`}</li>
      <li parentName="ol">{`Unter "CSS-Medienfunktion forced-colors emulieren" wählt ihr "forced-colors: active" aus.`}</li>
    </ol>
    <h2>{`Die Medienfunktion `}<InlineCode mdxType="InlineCode">{`forced-colors`}</InlineCode></h2>
    <p>{`Passt das Styling eurer Website explizit mit der Medienfunktion `}<InlineCode mdxType="InlineCode">{`forced-colors`}</InlineCode>{` an.
Zum Beispiel habe ich in meinem Blog ein SVG-Muster mithilfe der
Eigenschaft `}<InlineCode mdxType="InlineCode">{`background-image`}</InlineCode>{` eingebunden:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`#___gatsby {
    background-image: url("data:image/svg+xml...");
    background-attachment: fixed;
    background-size: cover;
}`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`Mit der Media-Query entferne ich das Hintergrundbild, wenn der Forced Colors Modus aktiv ist:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`@media (forced-colors: active) {
    #___gatsby {
        background-image: none;
    }
}`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`Eine weitere nützliche CSS-Eigenschaft ist `}<InlineCode mdxType="InlineCode">{`forced-color-adjust`}</InlineCode>{`. Diese ermöglicht euch,
einzelne Elemente vor dem Zugriff des Forced Colors Modus zu schützen und selbst das Styling zu optimieren.`}</p>
    <h2>{`Transparente Rahmen und Umrisse`}</h2>
    <p>{`Web-Layouts vermitteln die Grenze eines Elements häufig über eine andere `}<InlineCode mdxType="InlineCode">{`background-color`}</InlineCode>{`
oder mit einem `}<InlineCode mdxType="InlineCode">{`box-shadow`}</InlineCode>{`. Im Forced Colors Modus haben jedoch alle Elemente dieselbe
Hintergrundfarbe und alle Schatten werden entfernt. Die fehlende Begrenzung von Elementen kann für
Nutzer:innen verwirrend sein.`}</p>
    <p>{`Kein Grund zu verzweifeln! Es gibt eine geniale Lösung für das Problem: Definiert eine Rahmenlinie mit transparenter
Farbe. Bei aktivem Forced Colors Modus wird die Rahmenlinie sichtbar und vermittelt eindeutig die Grenze des Elements:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`section.info-card {
    border: 1px solid transparent;
}`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`Auf dieselbe Weise könnt ihr für sichtbare Fokus-Indikatoren im Forced Colors Modus sorgen. Normalerweise setzen Browser
einen Umriss für fokussierte Links und Buttons. Es ist ok diesen mit `}<InlineCode mdxType="InlineCode">{`outline: none`}</InlineCode>{` zu
entfernen, wenn ihr den Fokus auf andere Weise (Hintergrundfarbe etc.) vermittelt. Da dieser Fokus-Indikator im Forced
Colors Modus verloren gehen würde, solltet ihr aber besser eine transparente `}<InlineCode mdxType="InlineCode">{`outline`}</InlineCode>{` setzen.`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`a:focus {
    background: var(--primary);
    color: var(--white);
    /* Provide visible focus indicator in forced colors mode */
    outline: 2px solid transparent;
}`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`Es gibt noch viele weitere CSS-Tricks, mit denen ihr verschiedene Probleme im Forced Colors Modus beheben könnt.
Bitte schaut euch das tolle Video `}<a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=vlx70ABSAP0"
      }}>{`„Practical Styling in Forced Colors Mode“`}</a>{`
von Mike Herchel an.`}</p>
    <h2>{`Nützliche Links`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://developer.mozilla.org/en-US/docs/Web/CSS/@media/forced-colors"
        }}>{`forced-colors (MDN)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://developer.mozilla.org/en-US/docs/Web/CSS/forced-color-adjust"
        }}>{`forced-color-adjust (MDN)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.smashingmagazine.com/2022/03/windows-high-contrast-colors-mode-css-custom-properties/"
        }}>{`Windows High Contrast Mode, Forced Colors Mode And CSS Custom Properties`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      